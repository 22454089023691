
export const film = [
    {
      title: 'fast talk',
      subtitle: '2020 / 3min',
      body: `This music video was a passion project that allowed me to explore all of the the creative possiblities that my motion graphics skills allowed for.`,
      link: 'https://www.youtube.com/embed/NSZNUepCbLY',
      size: 'widescreen',
      thumbnail: 'fasttalk.jpg',
    },
    {
      title: 'there it is',
      subtitle: '2020 / 2min',
      body: `This motion graphics project was inspired by the poem "There It Is" by Jayne Cortez. Each line's animation is intended to match its theme.`,
      link: 'https://www.youtube.com/embed/3capzEykkDQ',
      size: 'normal',
      extra: 'https://www.poetryfoundation.org/poems/90805/there-it-is',
      thumbnail: 'thereitis.jpg',
    },
    {
      title: 'coordinate plane',
      subtitle: '2020 / 2min',
      body: `This motion graphics project was an attempt to use different techniques and animations to visualize a specific song.`,
      link: 'https://www.youtube.com/embed/yBhUngk5VAA',
      size: 'normal',
      thumbnail: 'coordinate.jpg',
    },
    // {
    //   title: 'directing: final project',
    //   subtitle: '2019 / 4min',
    //   body: `This film was my final project for my directing course at Emerson College. The assignment: show a complete scene with a beginning, middle, and end using no dialogue.`,
    //   link: 'https://www.youtube.com/embed/n5UMn2q3HqQ',
    //   size: 'widescreen',
    //   thumbnail: 'directing.jpg',
    // },
    // {
    //   title: 'short personal documentary',
    //   subtitle: '2020 / 3min',
    //   body: `This personal documentary was made for my advanced cinematography course at Emerson College. After in-person classes were cancelled due to the Covid-19 crisis, we were tasked with making a documentary about our current situation. Trying to stay positive, mine focuses on my family and Maui, the island I grew up on.`,
    //   link: 'https://www.youtube.com/embed/nu238VPXb5I',
    //   size: 'widescreen',
    //   thumbnail: 'documentary.jpg',
    // },
    // {
    //   title: 'caress your soul',
    //   subtitle: '2019 / 3min',
    //   body: `	This music video was made as part of a 24 hour challenge. Special thanks to my parents who graciously offered to star in it on short notice.`,
    //   link: 'https://www.youtube.com/embed/3jzC1ogtfN4',
    //   size: 'widescreen',
    //   thumbnail: 'caress.jpg',
    // },
    // {
    //   title: 'the intelligent life of martin',
    //   subtitle: '2018 / 8min',
    //   body: `This is a short mockumentary film about Martin, a man on a mission to prove his worth and win his ex-girlfriend back.`,
    //   link: 'https://www.youtube.com/embed/fliwALVNTMY',
    //   size: 'normal',
    //   thumbnail: 'martin.jpg',
    // },
    {
      title: 'atx',
      subtitle: '2017 / 3min',
      body: `This is a short video attempting to highlight the culture of downtown Austin, TX.`,
      link: 'https://www.youtube.com/embed/Ux-4acCer-0',
      size: 'normal',
      thumbnail: 'atx.jpg',
    },
  ]

  export const photos = [
    {
      title: 'Boston, MA',
      path: 'boston.jpg',
    },
    {
      title: 'Krabi, Thailand',
      path: 'krabi.jpg',
    },
    {
      title: 'Da Nang, Vietnam',
      path: 'daNang.jpg',
    },
    {
      title: 'Da Nang, Vietnam',
      path: 'daNang2.jpg',
    },
    {
      title: 'Saigon, Vietnam',
      path: 'hochiminh.jpg',
    },
    {
      title: 'Trieste, Italy',
      path: 'trieste.jpg',
    },
    {
      title: 'Haleakala, Maui',
      path: 'haleakala.jpg',
    },
    {
      title: 'Paia, Maui',
      path: 'maui.jpg',
    },
    {
      title: 'Kihei, Maui',
      path: 'kihei.jpg',
    },
    {
      title: 'Flamenco Beach, Puerto Rico',
      path: 'puertoRico.jpg',
    },
    {
      title: 'Saint Louis, MO',
      path: 'stl.jpg',
    },
    {
      title: 'Seattle, WA',
      path: 'seattle.jpg',
    },
    {
      title: 'Seattle, WA',
      path: 'seattle2.jpg',
    },
  ]

 export const furniture = [
    {
      beforePath: 'before1.jpg',
      afterPath: 'after1.jpg',
    },
    {
      beforePath: 'before2.jpg',
      afterPath: 'after2.jpg',
    },
    {
      beforePath: 'before3.jpg',
      afterPath: 'after3.jpg',
    },
    {
      beforePath: 'before4.jpg',
      afterPath: 'after4.jpg',
    },
    {
      beforePath: 'before5.jpg',
      afterPath: 'after5.jpg',
    },
 ]

export const software = [
  {
    title: 'this website',
    subtitle: 'js / vue / css',
    body: `Maybe a bit meta, but building this site from scratch is one of the more intricate personal projects I've done. Using vue router for navigation and popmotion for transitions, this SPA gave me a chance to work on creating a fluid and mobile-friendly UX, and forced me to spend far too long tweaking CSS. Click the goblin on the homepage if you feel so inclined. `,
    imageSource: 'website.jpg',
  },
  {
    title: 'notes app',
    subtitle: 'js / react / css',
    body: `To make this site more intersting than a normal CRUD app, I wanted to add as much real-world functionality as possible. In this case, that meant adding features like password-protected notes, a search UI that saves any changes made during a search, and automatic scrolling to any note.`,
    link: 'https://notes.austinelwell.com',
    imageSource: 'notes.jpg',
  },
  {
    title: 'the reel list',
    subtitle: 'js / react / css',
    body: `This project was created for the film lover in me, who wanted a way to search for, read about, and sort my favorite films. Utilizing the Open Movie Database API, this app displays detailed info about any movie. In addition, it allows users to add films to a watchlist or 'my films' list and rate them on a scale of one to five.`,
    link: 'https://reel.austinelwell.com',
    imageSource: 'reel.jpg',
  },
  {
    title: 'bookmarks directory',
    subtitle: 'js / react / css',
    body: `This program was made as a challenge to work out the logistics of nested folders and recursive components. It allows users to create a directory similar to the native bookmark UI of several browsers, where you can add or delete links and folders.`,
    link: 'https://bookmarks.austinelwell.com',
    imageSource: 'bookmarks.jpg',
  },
  {
    title: 'time calculator',
    subtitle: 'js / react / css',
    body: `This idea for this project formed as I was doing an online coding puzzle, which asked me to convert a given amount of seconds into certain units. I created an app that, given a date, will tell you exactly how long it has been since (or until) that date, in the unit of your choice. Complete with randomized loading screens, it updates every second.`,
    link: 'https://time.austinelwell.com',
    imageSource: 'time.jpg',
  },
  {
    title: 'maze solver',
    subtitle: 'js / html / css',
    body: `This project was inspired by a youtube tutorial, but then became so much more. As one of my first JavaScript projects, I customized it to include several additional pieces of functionality such as the animation aspect of the maze solution, the ability to change the starting and ending points, and the numerous theme options. Just draw some walls and watch as the computer solves the maze.`,
    link: 'https://maze.austinelwell.com',
    imageSource: 'maze.jpg',
  },
]