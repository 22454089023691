<template>
  <div class="pageContainer">
    <div ref="n">
      <Navbar />
    </div>
    <SoftwareTileContainerAnimated class="allProjects" :pose="isVisible ? 'visible' : 'hidden'">
      <SoftwareTileAnimated v-for="project in softwareProjects" :key="project.title" >
        <SoftwareTile :project="project"/>
      </SoftwareTileAnimated>
    </SoftwareTileContainerAnimated>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import SoftwareTile from '@/components/SoftwareTile.vue'
import { tween, styler} from 'popmotion';
import posed from 'vue-pose';
import { software } from '@/assets/data.js'


export default {
  name: 'Software',
  components: {
    Navbar,
    SoftwareTile,
    SoftwareTileContainerAnimated: posed.div({
      visible: {
        delayChildren: 300,
        staggerChildren: 200,
      }
    }),
    SoftwareTileAnimated: posed.div({
      visible: { opacity: 1, y: 0  },
      hidden: { opacity: 0, y: 50 }
    })
  },
  data() {
    return {
      isVisible: false,
      prevRoute: null,
      softwareProjects: software
    }
  },
  beforeRouteEnter(to, from, next) {
    //gets the previous page so we know whether to animate
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
    //animates navbar if coming from entry page or new window
    let n = styler(this.$refs.n)
    if ([undefined, '/'].includes(this.prevRoute)) {
      tween({
        from: {
          opacity: 0,
          y: '50px',
        },
        to: {
          opacity: 1,
          y: '0px',
        },
        duration: 500,
      }).start(n.set)
    }
  },
}

</script>

<style scoped>

  .pageContainer {
    height: 100%;

  }

  .allProjects {
    margin: 50px;
  }

</style>